@charset "UTF-8";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@font-face {
  font-family: "Jost";
  src: url("./assets/fonts/jost-cufonfonts/Jost400Book.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Jost";
  src: url("./assets/fonts/jost-cufonfonts/Jost500Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Jost";
  src: url("./assets/fonts/jost-cufonfonts/Jost600Semi.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Jost";
  src: url("./assets/fonts/jost-cufonfonts/Jost700Bold.otf");
  font-weight: bold;
}
html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: "Jost";
  color: #1e1d23;
}

.bodyCont {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 15px;
}

a {
  text-decoration: none !important;
}

.primCol {
  color: #A97510;
}

.primBg {
  background-color: #A97510;
}

.primBtn {
  background: #A97510;
  color: #fff !important;
  padding: 11px 30px;
  border: none;
  font-size: 12px;
  letter-spacing: 2px;
  display: inline-block;
}
.primBtn:disabled, .primBtn.disabled {
  opacity: 0.6;
}

.paginationCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.paginationCont a {
  padding: 6px 15px;
  margin: 0 5px;
  color: #1e1d23;
  border: 1px solid #4949495d;
}
.paginationCont a:not(.disabled):hover {
  background: #A97510;
  color: #fff;
  border-color: #A97510;
}
.paginationCont a.disabled {
  opacity: 0.6;
}
.paginationCont a.cusActive {
  background: #A97510;
  border-color: #A97510;
  color: #fff;
}

/******************************************/
/******************************************/
.breadcrumb {
  background-color: transparent !important;
}

.page-title-box .page-title {
  font-size: 1.25rem;
  margin: 0;
  line-height: 75px;
  color: #323a46;
}

.page-title-box .page-title-right {
  float: right;
  margin-top: 22px;
}

.page-title-box .breadcrumb {
  padding-top: 5px;
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }

  .page-title-box .breadcrumb {
    display: none;
  }
}
@media (max-width: 640px) {
  .page-title-box .page-title-right {
    display: none;
  }
}
@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
#adminWrapper {
  background: #f2f5f7;
}

.form-heading {
  background: #f2f5f7;
  padding: 10px 13px;
  border-radius: 2px;
  margin-bottom: 30px;
}

.form-heading h4 {
  font-size: 14px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #666;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  /* adds animation for all transitions */
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

/* Makes sure that everything is 100% height */
/* gets the actual input out of the way;
we're going to style the label instead */
#drawer-toggle {
  position: absolute;
  opacity: 0;
}

#drawer-toggle-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0px;
  height: 50px;
  width: 50px;
  display: block;
  position: fixed;
  z-index: 100;
  top: 0px;
}

/* adds our "hamburger" menu icon */
#drawer-toggle-label:before {
  content: "";
  display: block;
  font-family: FontAwesome;
  position: absolute;
  left: 13px;
  top: 14px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  z-index: 100 !important;
}

.admin-header {
  width: 100%;
  position: fixed;
  background: #A97510;
  padding: 5px 10px 5px 50px;
  font-size: 30px;
  line-height: 30px;
  z-index: 1;
  top: 0px;
}

.admin-header .title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.admin-header span.pull-right {
  margin-right: 20px;
}

#drawer {
  position: fixed;
  top: 0;
  left: -270px;
  height: 100%;
  width: 270px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2000;
}

/* actual page content pane */
/* #pageContents {
  margin-left: 0px;
  margin-top: 60px;
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  // padding: 20px;
  padding-bottom: 20px;
} */
#pageContents {
  margin-left: 0px;
  margin-top: 60px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

#pageContents a {
  color: #A97510;
}

#drawer-toggle:checked ~ #drawer-toggle-label,
#drawer-toggle:checked ~ header {
  left: 270px;
}

#drawer-toggle:checked ~ #drawer {
  left: 0px;
}

#drawer-toggle:checked ~ #pageContents {
  margin-left: 270px;
}

#drawer-toggle:checked ~ #drawer-toggle-label {
  height: 100%;
  width: calc(100% - 270px);
  background: rgba(0, 0, 0, 0.4);
}

#drawer-toggle:checked ~ #drawer-toggle-label,
#drawer-toggle:checked ~ header {
  left: 270px;
}

@media only screen and (max-width: 1199px) {
  .custmDisplay-sidebar {
    display: none !important;
  }

  .custmDisplay {
    width: 100% !important;
  }
}
.admin-btn-sm {
  padding: 5px;
  font-size: 12px;
  padding: 4px 8px;
  color: #fff !important;
}

.adminUpload .uploadWrapper {
  width: 100%;
  border: 2px dashed #ccc;
  height: 200px;
}
.adminUpload .uploadWrapper .uploadLabel {
  width: 100%;
  height: 200px;
  cursor: pointer;
}
.adminUpload .uploadWrapper .uploadIcon {
  font-size: 40px;
  text-align: center !important;
  color: #ccc;
  margin-top: 65px;
}
.adminUpload .uploadWrapper p {
  color: #ccc;
  font-size: 13px;
}
.adminUpload .uploadWrapper .deleteImg {
  position: absolute;
  right: 10px;
  top: 7px;
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
}
.adminUpload .uploadWrapper .deleteImg i {
  color: #fff;
  margin-top: 4px;
}
.adminUpload .uploadWrapper .uploadProgress {
  position: absolute;
  top: 80px;
  width: 100%;
}
.adminUpload .uploadWrapper .uploaded {
  position: absolute;
  top: 7px;
  left: 15px;
  object-fit: contain;
  width: 90%;
  height: 90%;
}